// Configure requireJS
window.require = {
	baseUrl : '/js',
	baseBlocksUrl : 'blocks',
	paths : {
		jquery : 'vendor/jquery/jquery-3.6.0',
		'jquery.validate' : 'vendor/jquery/jquery.validate',
		'jquery.validate.wrapper' : 'wrappers/jquery.validate.wrapper',
		'jquery.validate.unobtrusive' : 'vendor/jquery/jquery.validate.unobtrusive',
		'jquery.validate.unobtrusive.wrapper' : 'wrappers/jquery.validate.unobtrusive.wrapper',
		'jquery.validate.unobtrusive-ajax' : 'vendor/jquery/jquery.validate.unobtrusive-ajax',
		Modernizr : 'vendor/core/modernizr',
		b : 'block'
	},
	packages : [
		{
			name : 'moment',
			location : 'vendor',
			main : 'moment'
		}
	],
	shim : {
		'vendor/jquery/mCustomScrollbar' : [ 'jquery' ],
		'vendor/prettify/prettify' : [ 'jquery' ],
		'vendor/pikaday' : [ 'moment' ],
		'jquery.validate.unobtrusive-ajax' : [ 'jquery', 'jquery.validate', 'jquery.validate.unobtrusive' ],
		Modernizr : { exports : 'Modernizr' }
	},
	map : {
		'*' : {
			'jquery.validate' : 'jquery.validate.wrapper',
			'jquery.validate.unobtrusive' : 'jquery.validate.unobtrusive.wrapper'
		},
		'jquery.validate.wrapper' : { 'jquery.validate' : 'jquery.validate' },
		'jquery.validate.unobtrusive.wrapper' : { 'jquery.validate.unobtrusive' : 'jquery.validate.unobtrusive' }
	},
	callback : function() {
		'use strict';
		var event;
		if ( typeof Event === 'function' ) {
			event = new Event( 'requireready' );
		} else {
			event = document.createEvent( 'Event' );
			event.initEvent( 'requireready', true, true );
		}
		document.dispatchEvent( event );
	}
};

// Extend require config
( function func() {
	'use strict';
	var require,
		requireExtend,
		objs,
		arrs,
		blocked,
		origCallback,
		initScript,
		currentUrl,
		appUrl,
		head,
		script;

	// Check browser has minium level of feature support
	if ( 'querySelector' in document && 'sessionStorage' in window && 'addEventListener' in window ) {
		// Object.assign polyfill
		if ( typeof Object.assign !== 'function' ) {
			// Must be writable: true, enumerable: false, configurable: true
			Object.defineProperty( Object, 'assign', {
				value : function assign( target, varArgs ) { // eslint-disable-line no-unused-vars
					var to,
						index,
						nextSource,
						nextKey;

					// .length of function is 2
					if ( target === null ) { // TypeError if undefined or null
						throw new TypeError( 'Cannot convert undefined or null to object' );
					}

					to = Object( target );

					for ( index = 1; index < arguments.length; index++ ) {
						nextSource = arguments[ index ];

						if ( nextSource !== null ) { // Skip over if undefined or null
							for ( nextKey in nextSource ) {
								// Avoid bugs when hasOwnProperty is shadowed
								if ( Object.prototype.hasOwnProperty.call( nextSource, nextKey ) ) {
									to[ nextKey ] = nextSource[ nextKey ];
								}
							}
						}
					}
					return to;
				},
				writable : true,
				configurable : true
			});
		}


		require = window.require;
		requireExtend = window.requireExtend;
		objs = [ 'paths', 'bundles', 'shim', 'map', 'config' ];
		arrs = [ 'packages', 'deps' ];
		blocked = [ 'baseUrl', 'skipDataMain', 'baseBlocksUrl' ];

		if ( requireExtend && typeof requireExtend === 'object' ) {
			Object.keys( requireExtend ).forEach( function loop( prop ) {
				var x, y;
				if ( blocked.indexOf( prop ) > -1 ) {
					throw new Error( 'You are not permitted to override `' + prop + '` in your config' );
				}

				if ( objs.indexOf( prop ) > -1 ) {
					require[ prop ] = Object.assign({}, requireExtend[ prop ], require[ prop ] );
					return;
				}

				if ( arrs.indexOf( prop ) > -1 ) {
					x = require[ prop ] || [];
					y = requireExtend[ prop ] || [];
					require[ prop ] = [].concat( x, y );
					return;
				}

				if ( prop === 'callback' && requireExtend.callback === 'function' ) {
					// Callback already exists on config, wrap and call both
					if ( require.callback ) {
						origCallback = require.callback;
						require.callback = function callback() {
							origCallback.apply( this, arguments );
							requireExtend.callback.apply( this, arguments );
						};
					} else {
						require.callback = requireExtend.callback;
					}
					return;
				}

				require[ prop ] = requireExtend[ prop ];
			});
		}

		if ( sessionStorage.getItem( 'fonts-loaded' ) === 'success' ) {
			window.document.documentElement.className += ' fonts-loaded';
		}

		// Set base URL based on where init.js is being loaded from
		initScript = document.getElementById( 'js-init' );
		if ( initScript !== null ) {
			currentUrl = initScript.src;
			require.baseUrl = currentUrl.replace( '/init.js', '' );

			appUrl = initScript.getAttribute( 'data-app' );
			if ( appUrl !== null ) {
				require.paths.app = appUrl;
			}
		}

		// Insert requireJS in page
		head = document.getElementsByTagName( 'head' )[ 0 ];
		script = document.createElement( 'script' );
		script.src = require.baseUrl + '/vendor/core/require.js';
		script.setAttribute( 'data-main', 'main' );
		head.appendChild( script );
	}
})();
